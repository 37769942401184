import { Controller } from '@hotwired/stimulus';
import * as LSMLEditor from '@cph/lsb-ckeditor';

const CONTROL_COMMAND_MAP = {
  blockStyle: 'heading',
  responsorial: 'applyResponsorial',
};

function capitalize(string) {
  return string.charAt(0).toLocaleUpperCase() + string.slice(1);
}

export default class LsmlEditorController extends Controller {
  static targets = [
    'editor',
    'toolbar',
    'input',
    'toggleControl',
    'actionControl',
    'listControl',
    'placeholderForm',
  ];
  static values = {
    hideSymbols: Boolean,
    chantPipe: { type: String, default: '|' },
    assetHost: String,
  };

  #editor = null;

  // ==============
  // Lifecycle
  // ==============

  connect() {
    this._initializeEditor();
  }

  // ==============
  // Actions
  // ==============

  preventDefault(event) {
    event.preventDefault();
  }

  toggleControl(event) {
    event.preventDefault();
    const target = this.toggleControlTargets.find(
      (target) => target.id === event.currentTarget.dataset.toggleTarget,
    );
    if (target) {
      target.checked = !target.checked;
      this.fireEditorCommand({ currentTarget: target });
    }
  }

  fireActionCommand(event) {
    event.preventDefault();

    if (event.currentTarget.dataset.controlName === 'insertPlaceholder') {
      this.placeholderFormTarget.showModal();
    } else {
      this.fireEditorCommand(event);
    }
  }

  fireEditorCommand({ currentTarget }) {
    this.#editor?.execute(currentTarget.dataset.controlName);
  }

  applyBlockStyle({ target }) {
    if (!this.#editor) {
      return;
    }

    const value = target.selectedOptions[0]?.value;
    if (!value) {
      return;
    }

    if (value === 'paragraph') {
      this.#editor.execute('paragraph');
    } else {
      this.#editor.execute('heading', { value });
    }

    this.#editor.editing.view.focus();
  }

  applyResponsorial({ target }) {
    if (!this.#editor) {
      return;
    }

    const selectedOption = target.selectedOptions[0];
    if (!selectedOption) {
      return;
    }

    const icon = selectedOption.dataset.icon,
      speaker = selectedOption.dataset.speaker;
    if (icon && speaker) {
      this.#editor.execute('applyResponsorial', { icon, speaker });
    } else {
      this.#editor.execute('removeResponsorial');
    }

    this.#editor.editing.view.focus();
  }

  cancelPlaceholder(event) {
    event.preventDefault();
    this.placeholderFormTarget.close();
  }

  confirmPlaceholder(event) {
    event.preventDefault();

    if (!this.#editor) {
      return;
    }

    const name = this.placeholderFormTarget.querySelector('select').value,
      fallback = this.placeholderFormTarget.querySelector('input').value;
    this.#editor.execute('insertPlaceholder', { name, fallback });
    this.placeholderFormTarget.close();
  }

  // ==============
  // Initialization
  // ==============

  async _initializeEditor() {
    this.editorTarget.value = this.inputTarget.value;
    const editor = await LSMLEditor.createEditor(this.editorTarget, {
      autosave: (value) => {
        this.inputTarget.value = value;
      },
      options: {
        responsorials: { hideSymbols: this.hideSymbolsValue },
        chantPipe: { divider: this.chantPipeValue },
        assetHost: this.assetHostValue,
      },
    });

    this.#editor = editor;

    // Manage enabled/disabled state of all controls
    for (const control of this.toggleControlTargets
      .concat(this.actionControlTargets)
      .concat(this.listControlTargets)) {
      const controlName = control.dataset.controlName;
      const commandName = CONTROL_COMMAND_MAP[controlName] || controlName;
      const command = editor.commands.get(commandName);
      command.on('change:enabled', () => {
        control.disabled = !command.isEnabled;
      });
      control.disabled = !command.isEnabled;
    }

    // Manage active state of toggle controls
    for (const toggleControl of this.toggleControlTargets) {
      const controlName = toggleControl.dataset.controlName;
      const commandName = CONTROL_COMMAND_MAP[controlName] || controlName;
      const command = editor.commands.get(commandName);
      command.on('change:value', () => {
        toggleControl.checked = command.value;
      });
      toggleControl.checked = command.value;
    }

    // Manage state of list controls
    for (const listControl of this.listControlTargets) {
      const controlName = listControl.dataset.controlName;
      const commandName = CONTROL_COMMAND_MAP[controlName] || controlName;
      const command = editor.commands.get(commandName);
      command.on('change:value', () => {
        this[`_update${capitalize(controlName)}Selection`](
          listControl,
          command.value,
        );
      });
      this[`_update${capitalize(controlName)}Selection`](
        listControl,
        command.value,
      );
    }
  }

  // ==============
  // Utilities
  // ==============

  _updateBlockStyleSelection(element, value) {
    const effectiveValue = value || 'paragraph';
    const newIndex = Array.from(element.options).findIndex(
      (option) => option.value === effectiveValue,
    );
    element.selectedIndex = newIndex || 0;
  }

  _updateResponsorialSelection(element, value) {
    const newIndex = Array.from(element.options).findIndex(
      (option) => option.dataset.speaker === value?.speaker,
    );
    element.selectedIndex = newIndex || 0;
  }

  _scopedName(name) {
    return `${this.inputTarget.name}_${name}`;
  }
}
